import React from "react";
import { useAuth0 } from '@auth0/auth0-react';

function LoginLayout () {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isAuthenticated && !isLoading) {
    window.location.replace("/")
  } else if (!isLoading) {
    window.location.replace("/unauthorized")
  }

  return (
    <>
      <p>Logging you in...</p>
    </>
  )
}

export default LoginLayout;
import React from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import palette from './palette.js';
import typography from './typography.js';


const theme = createTheme({
  palette: palette,
  typography: typography
});

function Provider({ children }) {
  return <ThemeProvider theme={theme} children={children} />
}

export default Provider;

import { createTheme } from '@mui/material/styles';
const theme = createTheme();

const defaultFontFamily = "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif"

const standardTypography = {
  h1: {
    fontFamily: defaultFontFamily,
    fontSize: "2.25rem",
    fontWeight: 300,
    letterSpacing: "0.008em",
    lineHeight: 1.5,
    [theme.breakpoints.down('md')]: {
      fontSize: "1.75rem",
      lineHeight: 1.75
    }
  },
  h2: {
    fontFamily: defaultFontFamily,
    fontSize: "2rem",
    fontWeight: 300,
    letterSpacing: "0.008em",
    lineHeight: 1.5,
    [theme.breakpoints.down('md')]: {
      fontSize: "1.5rem",
      lineHeight: 1.75
    }
  },
  h3: {
    fontFamily: defaultFontFamily,
    fontSize: "1.75rem",
    fontWeight: 300,
    letterSpacing: "0.0075em",
    lineHeight: 1.5,
    [theme.breakpoints.down('md')]: {
      fontSize: "1.35rem",
      lineHeight: 1.75
    }
  },
  h4: {
    fontFamily: defaultFontFamily,
    fontSize: "1.5rem",
    fontWeight: 300,
    letterSpacing: "-0.0.0075em",
    lineHeight: 1.5,
    [theme.breakpoints.down('md')]: {
      fontSize: "1.2rem",
      lineHeight: 1.75
    }
  },
  h5: {
    fontFamily: defaultFontFamily,
    fontSize: "1.25rem",
    fontWeight: 300,
    letterSpacing: "0em",
    lineHeight: 1.5,
    [theme.breakpoints.down('md')]: {
      fontSize: "1.0rem",
      lineHeight: 1.75
    }
  },
  h6: {
    fontFamily: defaultFontFamily,
    fontSize: "1rem",
    fontWeight: 300,
    letterSpacing: "0em",
    lineHeight: 1.5,
    [theme.breakpoints.down('md')]: {
      fontSize: "1.00rem",
      lineHeight: 1.75
    }
  },
  body1: {
    fontFamily: defaultFontFamily,
    fontSize: "1.rem",
    letterSpacing: "0em",
    lineHeight: 1.25,
    [theme.breakpoints.down('md')]: {
      fontSize: "0.85rem",
      lineHeight: 1.5
    }
  },
  body2: {
    fontFamily: defaultFontFamily,
    fontSize: "1rem",
    letterSpacing: "0em",
    lineHeight: 1.25,
    [theme.breakpoints.down('md')]: {
      fontSize: "0.85rem",
      lineHeight: 1.5
    }
  }
}

const appBarTypography = {
  "appBar-title": {
    fontFamily: defaultFontFamily,
    fontSize: "1.25rem",
    fontWeight: 300,
    letterSpacing: "0.008em",
    lineHeight: 1.2,
  }
}

const typography = {
  fontFamily: defaultFontFamily,
  fontSize: 14,
  ...standardTypography,
  ...appBarTypography
}

export default typography;
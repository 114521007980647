import React from "react";
import { Route, Routes } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import { withAuthenticationRequired } from "@auth0/auth0-react";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import LandingPage from '../pages/LandingPage.js';

import { useTheme } from '@mui/material/styles';

import styles from "./dashboard.module.css";

function DashboardLayout() {
  const theme = useTheme();
  const { logout } = useAuth0();

  const handleLogout = () => { logout(); }

  const navBar = (
    <AppBar position="sticky">
      <Toolbar sx={{ display: 'flex', alignItems: 'center', height: '64px' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="appBar-title">
            LSJUMB Admin Portal
          </Typography>
        </Box>
        <Box>
          <Button
            variant="text"
            color="white"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );

  return (
    <div
      className={styles.body}
      style={{ backgroundColor: theme.palette.grey[100] }}
    >
      {navBar}
      <Routes>
        <Route path="/*" element={<LandingPage />}></Route>
      </Routes>
    </div>
  );
}

export default withAuthenticationRequired(DashboardLayout);
import React from "react";
import { useAuth0 } from '@auth0/auth0-react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { useTheme } from '@mui/material/styles';

import styles from "./unauthorized.module.css";

function UnauthorizedLayout() {
  const theme = useTheme();
  const { logout, isAuthenticated, isLoading } = useAuth0();
  
  React.useEffect(() => {
    if (isAuthenticated) { window.location.replace("/"); }
  }, [isAuthenticated]);

  const handleLogout = () => { logout(); }

  const navBar = (
    <AppBar position="sticky">
      <Toolbar sx={{ display: 'flex', alignItems: 'center', height: '64px' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="appBar-title">
            LSJUMB Admin Portal
          </Typography>
        </Box>
        <Box>
          <Button
            variant="text"
            color="white"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );

  const modal = (
    <Dialog open={true}>
      <Box sx={{ m: '32px' }}>
        <Typography variant="h2">
          Hey there! 
        </Typography>
        <Typography sx={{ mt: 2 }}>
          It look&apos;s like this account doesn't have
          access to this dashboard. Please log in using
          an official LSJUMB account, or contact an authorized user.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '24px' }}>
          <Button
            variant="outlined"
            color="black"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </Box>
    </Dialog>
  )

  return (
    <div
      className={styles.body}
      style={{ backgroundColor: theme.palette.grey[100] }}
    >
      {navBar}
      {!isLoading && modal}
    </div>
  );
}

export default UnauthorizedLayout;
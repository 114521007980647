import React from "react";
import { Route, Routes } from "react-router-dom";

import DashboardLayout from "./layouts/dashboard.js";
import LoginLayout from "./layouts/login.js";
import UnauthorizedLayout from "./layouts/unauthorized.js";

function App() {
  return (
    <div className="App">
      <header className="App-header">
      </header>
      <Routes>
        <Route path ="/login" element={<LoginLayout />} />
        <Route path ="/unauthorized" element={<UnauthorizedLayout />} />
        <Route path="/*" element={<DashboardLayout />} />
      </Routes>
    </div>
  );
}

export default App;

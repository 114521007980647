import red from '@mui/material/colors/red';
import pink from '@mui/material/colors/pink';
import purple from '@mui/material/colors/purple';
import deepPurple from '@mui/material/colors/deepPurple';
import indigo from '@mui/material/colors/indigo';
import blue from '@mui/material/colors/blue';
import lightBlue from '@mui/material/colors/lightBlue';
import cyan from '@mui/material/colors/cyan';
import teal from '@mui/material/colors/teal';
import green from '@mui/material/colors/green';
import lightGreen from '@mui/material/colors/lightGreen';
import lime from '@mui/material/colors/lime';
import yellow from '@mui/material/colors/yellow';
import amber from '@mui/material/colors/amber';
import orange from '@mui/material/colors/orange';
import deepOrange from '@mui/material/colors/deepOrange';
import brown from '@mui/material/colors/brown';
import grey from '@mui/material/colors/grey';
import blueGrey from '@mui/material/colors/blueGrey';

const themeColors = {
  white: {
    main: "#FFFFFF",
  },
  black: {
    light: "#12263F",
    main: "#000000",
  },
  colors: {
    red, pink, purple, deepPurple, indigo, blue, lightBlue, cyan,
    teal, green, lightGreen, lime, yellow, amber, orange, deepOrange, brown,
    grey, blueGrey
  },
  customColors: {
    cardinal: '#b1040e',
    tan: '#fef1e4',
    spirited: '#F4795B',
    poppy: '#F9A44A'
  },
  primary: {
    main: red[600],
  },
  secondary: {
    main: amber[400],
  }
};

export default themeColors;
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./App.js";

import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import ThemeProvider from "./theme/theme.js";

let AUTH0_DOMAIN = "lsjumb-app-dev.us.auth0.com"
let AUTH0_CLIENT_ID = "JyD9EyoYKd1N3N0KpYtDNB8VN9CYOw2Y"

if (process.env.NODE_ENV === "production") {
  AUTH0_DOMAIN = "auth.lsjumb.org";
  AUTH0_CLIENT_ID = "xdp9jjKOZ6oGSOYMhkCwEgKmuV7rwpC9"
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_CLIENT_ID}
        redirectUri={`${window.location.origin}/login`}
        audience='admin-express'
      >
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </Auth0Provider>
    </BrowserRouter>
  </React.StrictMode>
);